<template>
  <div class="profile-info">
    <div class="profile-info__item">
      <div class="profile-info__count"><span>$</span>{{ bonusBalance }}</div>
      <div class="profile-info__label">{{ $t("profileBonusLabel") }}</div>
    </div>
    <!-- <div class="profile-info__item">
      <div class="profile-info__count">{{ totalParcels || 0 }}</div>
      <div class="profile-info__label">{{ $t('profileTotalParcelsLabel') }}</div>
    </div>
    <div class="profile-info__item">
      <div class="profile-info__count">{{ activeParcels || 0 }}</div>
      <div class="profile-info__label">{{ $t('profileActiveParcelsLabel') }}</div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "ProfileInfo",
  props: {
    bonusBalance: Number,
    totalParcels: Number,
    activeParcels: Number,
  },
};
</script>

<style>
.profile-info {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  justify-content: center;
  margin-top: 30px;
}
.profile-info__item {
  /* border-right: 1px solid #F1F1F1; */
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
}
.profile-info__count {
  font-size: 24px;
  font-family: "Helvetica Neue Medium";
  line-height: 24px;
}
.profile-info__label {
  color: #696969;
  font-size: 12px;
  line-height: 16px;
  margin-top: 6px;
}
</style>
