<template>
  <div class="profile-header">
    <div class="profile-header__back" @click="onBack">
      <img src="@/assets/img/arr-l-w.svg" />
    </div>
    <div class="profile-header__title">{{$t('settingsSB')}}</div>
    <div
      v-if="!isSocialOpened"
      class="profile-header__actions"
      @click="isSocialOpened = true"
    >
      <img src="@/assets/img/Question.svg" />
    </div>
    <div
      @click="isSocialOpened = false"
      class="profile-header__actions-close"
      v-else
    >
      <img src="@/assets/img/parcel/Close.svg" />
    </div>

    <social-help :isSocialOpened.sync="isSocialOpened" />
  </div>
</template>

<script>
import SocialHelp from "@/components/SocialHelp.vue";

export default {
  name: "ProfileHeader",
  components: { SocialHelp },
  data() {
    return {
      isSocialOpened: false,
    };
  },
  methods: {
      onBack() {
        this.$router.push('/')
      }
  },
};
</script>

<style>
.profile-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}
.profile-header__back {
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-header__actions {
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-header__actions-close {
  background: #fff;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  z-index: 4;
}
.profile-header__title {
  flex: 1 auto;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-family: "Helvetica Neue Medium";
}
.profile-header .social-helpers {
  top: 4px;
  right: 5px;
}
</style>