<template>
  <div class="profile-entity" @click="onTap">
    <div class="profile-entity__icon">
      <slot name="base-icon"></slot>
    </div>
    <div class="profile-entity__info">
      <div v-if="label" class="profile-entity__label">
        {{ label }}
      </div>
      <div class="profile-entity__value">
        {{ value }}
        <span v-if="isNew">({{ $t("isNew") }})</span>
      </div>
    </div>
    <div class="profile-entity__action">
      <slot name="action-icon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileEntity",
  props: {
    label: String,
    value: String,
    onTap: {
      type: Function,
      default: () => true,
    },
    isNew: Boolean,
  },
};
</script>

<style lang="scss">
.profile-entity {
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  min-height: 58px;
  box-sizing: border-box;
  align-items: center;
}
.profile-entity__info {
  flex: 1 auto;
  padding-top: 6px;
  padding-bottom: 6px;
}
.profile-entity__icon {
  width: 24px;
  min-width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 16px;

  img {
    max-width: 100%;
  }
}
.profile-entity__action {
  min-width: 40px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.profile-entity__label {
  color: #696969;
  font-size: 12px;
  margin-bottom: 6px;
  line-height: 14px;
}
.profile-entity__value {
  color: #282828;
  font-size: 16px;
  line-height: 18px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  span {
    color: #91c258;
    font-size: 16px;
  }
}
</style>
