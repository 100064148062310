<template>
  <div class="profile-avatar">
    <div class="profile-avatar__photo" @click="isAvatarSelectOpened = true">
      <img :src="require(`@/assets/img/avatars/Avatar-${selectedAvatar}.svg`)" />
    </div>
    <div class="profile-avatar__name">{{ userName }}</div>
    <div class="profile-avatar__id">{{ userId }}</div>

    <easy-bottom-sheet
      :isOpened.sync="isAvatarSelectOpened"
      :title="$t('changeAvatarTitle')"
    >
      <div class="profile-avatar__list">
        <div
          v-for="avatarId in avatarsList"
          :key="avatarId"
          class="profile-avatar__list-item"
          :class="{ isActive: avatarId === selectedAvatar }"
          @click="onSelect(avatarId)"
        >
          <div
            v-if="avatarId === selectedAvatar"
            class="profile-avatar__active-icon"
          >
            <img src="@/assets/img/i-success.svg" />
          </div>
          <img :src="require(`@/assets/img/avatars/Avatar-${avatarId}.svg`)" />
        </div>
      </div>
    </easy-bottom-sheet>
  </div>
</template>

<script>
import EasyBottomSheet from "@/components/common/EasyBottomSheet.vue";
export default {
  name: "ProfileAvatar",
  components: { EasyBottomSheet },
  props: {
    userId: Number,
    userName: String,
  },
  data() {
    return {
      avatarsList: [30, 8, 10, 12, 13, 16, 19, 27, 15],
      selectedAvatar: window.localStorage.getItem(`selectedAvatar`) || 8,
      isAvatarSelectOpened: false,
    };
  },
  methods: {
    onSelect(id) {
      this.selectedAvatar = id;
      window.localStorage.setItem(`selectedAvatar`, `${id}`);
      this.isAvatarSelectOpened = false;
    },
  },
};
</script>

<style>
.profile-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.profile-avatar__photo {
  width: 98px;
  height: 98px;
  border: 4px solid #f1f1f1;
  border-radius: 50%;
  margin-top: -49px;
  background: #fff;
  position: relative;
  overflow: hidden;
}
.profile-avatar__photo img {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate(-50%, -50%);
  width: 100%;
}
.profile-avatar__name {
  font-size: 24px;
  color: #282828;
  font-family: "Helvetica Neue Medium";
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 6px;
  text-align: center;
}
.profile-avatar__id {
  color: #696969;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
}
.profile-avatar__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;
  justify-content: space-between;
}
.profile-avatar__list-item {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border: 4px solid transparent;
  border-radius: 50%;
  position: relative;
}
.profile-avatar__list-item.isActive {
  border: 4px solid #f1f1f1;
}
.profile-avatar__active-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  background: #91c258;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -4px;
  right: -4px;
}
</style>