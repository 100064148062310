<template>
  <div class="profile__container">
    <div class="refferal-page__konfetti">
      <img src="@/assets/img/konfetti.svg" />
    </div>
    <profile-header />
    <div class="profile__content">
      <profile-avatar :userId="user.ID" :userName="user.pr_222" />
      <profile-info :bonusBalance="user.pr315" />

      <div class="profile__settings">
        <profile-entity
          :label="$t('profileHomeCountry')"
          :value="profileCountry"
        >
          <img slot="base-icon" src="@/assets/img/profile/Home.svg" />
        </profile-entity>
        <profile-entity
          :label="$t('profilePhoneNumber')"
          :value="user.st_11010"
        >
          <img slot="base-icon" src="@/assets/img/profile/Phone.svg" />
        </profile-entity>
        <profile-entity
          v-if="sharedGoods >= 3"
          :value="$t('profileShare')"
          :onTap="() => this.$router.push('/share-product')"
          isNew
        >
          <img slot="base-icon" src="@/assets/img/gromophon.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/ArrRight.svg" />
        </profile-entity>
        <profile-entity
          :onTap="() => (isChangeLangOpened = true)"
          :label="$t('profileLanguage')"
          :value="currentLang.caption"
        >
          <img slot="base-icon" src="@/assets/img/profile/Earth.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/ArrDown.svg" />
        </profile-entity>
        <profile-entity
          :value="$t('profileChangePassword')"
          :onTap="() => (isChangePasswordOpened = true)"
        >
          <img slot="base-icon" src="@/assets/img/profile/Finger.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/ArrRight.svg" />
        </profile-entity>
        <profile-entity :value="$t('profileExit')" :onTap="onExit">
          <img slot="base-icon" src="@/assets/img/profile/Exit.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/ArrRight.svg" />
        </profile-entity>
        <profile-entity
          :value="$t('removeAccountTitle')"
          :onTap="onRemoveAccount"
        >
          <img slot="base-icon" src="@/assets/img/profile/RemoveUser.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/ArrRight.svg" />
        </profile-entity>
      </div>

      <!-- <easy-subscribe /> -->
      <profile-subscribe />

      <div class="profile__version">{{ $t("version") }}: {{ appVersion }}</div>
    </div>

    <easy-bottom-sheet
      :isOpened.sync="isRemoveAccOpened"
      :title="$t('removeAccountTitle')"
    >
      <div class="remove-account-text">
        {{ $t("removeAccountText") }}
      </div>
      <easy-input
        v-model="removeAccWord"
        :label="$t('removeAccWord')"
        placeholder="DELETE"
        :isError="isRemoveAccWordSubmitted && !isRemoveConfirmWordValid"
      />
      <easy-input
        v-model="removeAccPassword"
        :label="$t('currentPassword')"
        :errorText="
          isRemoveAccPasswordWrong
            ? $t('oldPasswordWrongError')
            : $t('oldPasswordError')
        "
        :isError="
          (isRemoveAccWordSubmitted && removeAccPassword.length < 6) ||
            isRemoveAccPasswordWrong
        "
        isPassword
      />

      <template v-slot:footer>
        <button
          :class="{
            btnWideRound: true,
            disabled: !isDeleteAccountValid,
            isLoading: isRemoveAccLoading,
          }"
          @click="onRemoveAccountSubmit"
        >
          {{ $t("removeAccButton") }}
        </button>
      </template>
    </easy-bottom-sheet>

    <easy-bottom-sheet
      :isOpened.sync="isChangeLangOpened"
      :title="$t('changeLangTitle')"
    >
      <div class="profile-lang__container">
        <div
          class="profile-lang__item"
          v-for="(o, i) in LOCALES"
          :key="i"
          @click="onChangeLang(o.value)"
        >
          <div
            class="profile-lang__checkbox"
            :class="{ isActive: o.value === $i18n.locale }"
          >
            <img src="@/assets/img/i-success.svg" />
          </div>
          <div class="profile-lang__label">{{ o.caption }}</div>
        </div>
      </div>
    </easy-bottom-sheet>

    <easy-bottom-sheet
      :isOpened.sync="isChangePasswordOpened"
      :title="$t('changePasswordTitle')"
    >
      <easy-input
        v-model="oldPassword"
        :label="$t('currentPassword')"
        :errorText="
          isOldPasswordWrong
            ? $t('oldPasswordWrongError')
            : $t('oldPasswordError')
        "
        :isError="(isPasswordSubmitted && !oldPassword) || isOldPasswordWrong"
        isPassword
      />
      <easy-input
        v-model="newPassword"
        :label="$t('newPassword')"
        :errorText="$t('newPasswordError')"
        :isError="isPasswordSubmitted && !newPassword"
        isPassword
      />
      <easy-input
        v-model="confirmNewPassword"
        :label="$t('confirmNewPassword')"
        :errorText="$t('confirmPasswordError')"
        :isError="isPasswordSubmitted && confirmNewPassword != newPassword"
        isPassword
      />

      <template v-slot:footer>
        <button
          :class="{
            btnWideRound: true,
            disabled: !isChangePasswordValid,
          }"
          @click="onChangePassword"
        >
          {{ $t("btnSave") }}
        </button>
      </template>
    </easy-bottom-sheet>
  </div>
</template>

<script>
import ProfileHeader from "./ProfileHeader.vue";
import ProfileAvatar from "./ProfileAvatar.vue";
import ProfileInfo from "./ProfileInfo.vue";
import ProfileEntity from "./ProfileEntity.vue";
import ProfileSubscribe from "./ProfileSubscribe.vue";
import EasyBottomSheet from "@/components/common/EasyBottomSheet.vue";
import EasyInput from "@/components/common/EasyInput.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { LOCALES, defaultLocale } from "@/locales/locales";
import { changePassword, login } from "@/services/requests-list";
import { deleteAccount } from "@/services/api-connector";

export default {
  name: "Profile",
  components: {
    ProfileHeader,
    ProfileAvatar,
    ProfileInfo,
    ProfileEntity,
    ProfileSubscribe,
    EasyBottomSheet,
    EasyInput,
  },
  data() {
    return {
      LOCALES,
      defaultLocale,
      isChangeLangOpened: false,
      isChangePasswordOpened: false,

      newPassword: null,
      confirmNewPassword: null,
      oldPassword: null,
      isOldPasswordWrong: false,
      isPasswordSubmitted: false,

      isRemoveAccOpened: false,
      removeAccWord: "",
      removeAccPassword: "",
      isRemoveAccWordSubmitted: false,
      isRemoveAccLoading: false,
      isRemoveAccPasswordWrong: false,

      countryName: {
        285: "Ukraine",
        284: "USA",
        301: "United Kingdom",
        308: "Canada",
      },
    };
  },
  computed: {
    ...mapGetters("user", ["user", "sharedGoods"]),
    ...mapGetters("root", ["appVersion"]),
    currentLang() {
      return this.LOCALES.find((o) => o.value === this.$i18n.locale);
    },
    profileCountry() {
      return this.countryName[this.user.pr201];
    },
    isChangePasswordValid() {
      return (
        !!this.oldPassword &&
        !!this.newPassword &&
        this.newPassword == this.confirmNewPassword
      );
    },
    isRemoveConfirmWordValid() {
      return this.removeAccWord.toUpperCase() === "DELETE";
    },
    isDeleteAccountValid() {
      return (
        this.removeAccPassword.length >= 6 && this.isRemoveConfirmWordValid
      );
    },
  },
  methods: {
    ...mapMutations("root", ["setLanguage"]),
    ...mapActions("user", ["logout"]),
    onChangeLang(lang) {
      this.setLanguage(lang);
      this.isChangeLangOpened = false;
    },
    async onChangePassword() {
      this.isPasswordSubmitted = true;
      if (!this.isChangePasswordValid) return false;

      try {
        await changePassword({
          username: this.user.st_11010,
          phone: this.user.st_11010,
          newPassword: this.newPassword,
          oldPassword: this.oldPassword,
        });
        this.resetPasswordForm();
        this.$root.$emit("addMessage", {
          type: "success",
          message: this.$t("errLoginPass"),
        });
      } catch (err) {
        this.isOldPasswordWrong = true;
        console.log(err);
      }
    },
    resetPasswordForm() {
      this.newPassword = null;
      this.oldPassword = null;
      this.confirmNewPassword = null;
      this.isPasswordSubmitted = false;
      this.isChangePasswordOpened = false;
      this.isOldPasswordWrong = false;
    },
    onRemoveAccount() {
      this.isRemoveAccOpened = true;
    },
    async onRemoveAccountSubmit() {
      console.log("allo");
      this.isRemoveAccWordSubmitted = true;

      if (!this.isDeleteAccountValid) return;

      this.isRemoveAccLoading = true;

      try {
        const username = this.user.st_11010;
        const password = this.removeAccPassword;

        await login({ username, password });
      } catch (err) {
        this.isRemoveAccPasswordWrong = true;
        this.isRemoveAccLoading = false;
        return;
      }

      this.isRemoveAccPasswordWrong = false;

      try {
        await deleteAccount();
        this.onExit();
        this.$root.$emit("addMessage", {
          type: "success",
          message: this.$t("removeAccSuccess"),
        });
      } catch (err) {
        console.log("EROROR", err);
      }

      this.isRemoveAccLoading = false;
    },
    onExit() {
      this.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.profile__container {
  display: flex;
  flex-direction: column;
  background: #282828;
  margin: -84px -16px -98px;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}
.profile__container:after {
  display: block;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: -200px;
  height: 300px;
  background: #282828;
  z-index: -2;
}
.profile__content {
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex: 1 auto;
  margin-top: 128px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-bottom: 60px;
  position: relative;
}
.profile__settings {
  margin-top: 16px;
}
.profile-lang__item {
  display: flex;
  align-items: center;
}
.profile-lang__checkbox {
  border: 2px solid #bfbfbf;
  width: 22px;
  height: 22px;
  margin-right: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.profile-lang__checkbox.isActive {
  background: #91c258;
  border: 2px solid#91C258;
}
.profile-lang__label {
  border-bottom: 1px solid #f1f1f1;
  flex: 1 auto;
  padding-top: 18px;
  padding-bottom: 16px;
}
.profile-lang__item:last-child .profile-lang__label {
  border-bottom: 1px solid transparent;
}
.profile__version {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: #696969;
}
.remove-account-text {
  font-size: 12px;
  line-height: 1.4;
  color: #696969;
}
</style>
