<template>
  <div class="profile-subscribe">
    <div class="profile-subscribe__name">{{ $t("profileSubscribeName") }}</div>
    <div class="profile-subscribe__description">
      {{ $t("profileSubscribeDescription") }}
    </div>
    <div class="profile-subscribe__icons">
      <div class="profile-subscribe__item" @click="openViber">
        <img src="@/assets/img/parcel/Viber.svg" />
      </div>
      <div class="profile-subscribe__item" @click="openTelegram">
        <img src="@/assets/img/parcel/Telegram.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import eventsRegister from "@/services/events-register";
import { logEventFA, openExternal } from "@/utils/utils";
export default {
  name: "ProfileSubscribe",
  computed: {
    ...mapGetters("user", ["user"]),
  },
  methods: {
    openViber() {
      const viberBot = process.env.VUE_APP_VIBERBOT;

      if (!window["device"]) {
        openExternal(viberBot + "&context=" + this.user.pr236 + "&text=/start");
        this.onClose();
        return;
      }
      window["plugins"].launcher.launch(
        {
          uri: viberBot + "&context=" + this.user.pr236 + "&text=/start",
        },
        (res) => console.log("success viber", res),
        (err) => {
          console.log("viber", err);
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message: this.$t("errorOpenViber"),
          });
        }
      );
      logEventFA("select_content", {
        content_type: "viber",
        customer_id: this.user.ID,
      });
      this.onClose();
    },
    openTelegram() {
      const telegramBot = process.env.VUE_APP_TELEGRAMBOT;
      openExternal(`${telegramBot}?start=${this.user.pr236}`);
      logEventFA("select_content", {
        content_type: "telegram",
        customer_id: this.user.ID,
      });
      this.onClose();
    },
  },
};
</script>

<style>
.profile-subscribe {
  padding-top: 20px;
  padding-bottom: 20px;
}
.profile-subscribe__name {
  font-size: 16px;
  text-align: center;
  color: #282828;
  margin-bottom: 6px;
  font-family: "Helvetica Neue Medium";
}
.profile-subscribe__description {
  color: #696969;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  max-width: 245px;
  margin-left: auto;
  margin-right: auto;
}
.profile-subscribe__icons {
  display: flex;
  justify-content: center;
}
.profile-subscribe__item {
  padding: 12px;
}
</style>
